import loadable from '@loadable/component'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { styled, ThemeProvider } from '@material-ui/core/styles'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { Helmet } from 'react-helmet'

import { HoneybadgerErrorBoundaryProvider } from '../../hooks/useHoneybadger'
import { GlobalStyles } from '../../styles/global'
import theme, { rspTheme } from '../../styles/rspTheme'
import { LocaleProvider } from '../LocaleContext'
import SEO from '../SEO'

const Breadcrumb = loadable(() => import(`../Breadcrumb`))
const Footer = loadable(() => import(`../Footer`))
const Header = loadable(() => import(`../Header`))
const SkipLink = loadable(() => import(`../UI/SkipLink`))
const InitialModal = loadable(() => import(`../InitialModal`))

const queryClient = new QueryClient()

interface LayoutProps {
  children: React.ReactNode
  title?: string | null
  description?: string | null
  image?: {
    description: string | null
    url: string | null
  } | null
  locale?: string | null
  showBreadcrumb?: boolean
  slug: string
  breadcrumbLinks?: {
    text: string
    target?: string
  }[]
}

const LayoutWrapper = styled(Grid)({
  minHeight: `100vh`,
  flexWrap: `nowrap`,
  width: `100%`,
  margin: `0 auto 0`,
  outline: `20px solid white`,
  '& .mainContent': {
    paddingTop: rspTheme.navHeight,
    '@media (max-width: 980px)': {
      paddingTop: rspTheme.navHeightMobile,
    },
  },
})

const Layout = (props: LayoutProps) => {
  const {
    children,
    title,
    description,
    image,
    showBreadcrumb = true,
    breadcrumbLinks,
    locale = 'en-us',
    slug,
  } = props
  const data = useStaticQuery<GatsbyTypes.metaQuery>(graphql`
    query meta {
      contentstackResourcesWebsite {
        skip_link_text
      }
    }
  `)

  return (
    <HoneybadgerErrorBoundaryProvider>
      <QueryClientProvider client={queryClient}>
        <LayoutWrapper
          alignItems="stretch"
          container
          direction="column"
          justifyContent="flex-start"
        >
          <LocaleProvider pageLocale={locale}>
            <Helmet>
              {/* pardot */}
              <script>
                {`
                    piAId = '913921';
                    piCId = '5435';
                    piHostname = 'pi.pardot.com';

                    (function() {
                      function async_load(){
                        var s = document.createElement('script'); s.type = 'text/javascript';
                        s.src = ('https:' == document.location.protocol ? 'https://pi' : 'http://cdn') + '.pardot.com/pd.js';
                        var c = document.getElementsByTagName('script')[0];
                        c.parentNode.insertBefore(s,c);
                      }

                      if (window.attachEvent) {
                        window.attachEvent('onload', async_load);
                      } else {
                        window.addEventListener('load', async_load, false);
                      }
                    })();
                  `}
              </script>
              {/* truconversion (replace hotjar) */}
              <script type="text/javascript">
                {`
                     var _tip = _tip || [];
                     (function(d,s,id){
                         var js, tjs = d.getElementsByTagName(s)[0];
                         if(d.getElementById(id)) { return; }
                         js = d.createElement(s); js.id = id;
                         js.async = true;
                         js.src = d.location.protocol + '//app.truconversion.com/ti-js/36073/5d8c0.js';
                         tjs.parentNode.insertBefore(js, tjs);
                     }(document, 'script', 'ti-js'));
                  `}
              </script>
              {/* POSTHOG Tracking */}
              <script>
                {`!function(t,e){var o,n,p,r;e.__SV||(window.posthog=e,e._i=[],e.init=function(i,s,a){function g(t,e){var o=e.split(".");2==o.length&&(t=t[o[0]],e=o[1]),t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}}(p=t.createElement("script")).type="text/javascript",p.crossOrigin="anonymous",p.async=!0,p.src=s.api_host.replace(".i.posthog.com","-assets.i.posthog.com")+"/static/array.js",(r=t.getElementsByTagName("script")[0]).parentNode.insertBefore(p,r);var u=e;for(void 0!==a?u=e[a]=[]:a="posthog",u.people=u.people||[],u.toString=function(t){var e="posthog";return"posthog"!==a&&(e+="."+a),t||(e+=" (stub)"),e},u.people.toString=function(){return u.toString(1)+".people (stub)"},o="init capture register register_once register_for_session unregister unregister_for_session getFeatureFlag getFeatureFlagPayload isFeatureEnabled reloadFeatureFlags updateEarlyAccessFeatureEnrollment getEarlyAccessFeatures on onFeatureFlags onSessionId getSurveys getActiveMatchingSurveys renderSurvey canRenderSurvey getNextSurveyStep identify setPersonProperties group resetGroups setPersonPropertiesForFlags resetPersonPropertiesForFlags setGroupPropertiesForFlags resetGroupPropertiesForFlags reset get_distinct_id getGroups get_session_id get_session_replay_url alias set_config startSessionRecording stopSessionRecording sessionRecordingStarted captureException loadToolbar get_property getSessionProperty createPersonProfile opt_in_capturing opt_out_capturing has_opted_in_capturing has_opted_out_capturing clear_opt_in_out_capturing debug".split(" "),n=0;n<o.length;n++)g(u,o[n]);e._i.push([i,s,a])},e.__SV=1)}(document,window.posthog||[]);
                  posthog.init('${process.env.GATSBY_POSTHOG_KEY}',{api_host:'${process.env.GATSBY_POSTHOG_URL}', person_profiles: 'always'})
                `}
              </script>
            </Helmet>
            <SEO
              description={description || ''}
              imageUrl={image?.url || ''}
              lang={locale}
              slug={slug}
              title={title || ''}
            />
            <GlobalStyles />
            <ThemeProvider theme={theme}>
              <SkipLink
                linkTarget="main-content"
                linkText={
                  data?.contentstackResourcesWebsite?.skip_link_text ||
                  'Skip to main content'
                }
              />
              <Header isHomepage={!showBreadcrumb} locale={locale} />
              <Box className="mainContent" component="main" id="main-content">
                {showBreadcrumb && breadcrumbLinks ? (
                  <Breadcrumb links={breadcrumbLinks} />
                ) : null}
                {children}
              </Box>
              <Footer locale={locale} />
              <InitialModal />
            </ThemeProvider>
          </LocaleProvider>
        </LayoutWrapper>
      </QueryClientProvider>
    </HoneybadgerErrorBoundaryProvider>
  )
}

export default Layout
