import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'

type SEOProps = {
  description?: string
  imageUrl?: string
  lang?: string | null
  meta?: Array<any>
  title: string
  slug: string
}

const SEO = ({
  description = '',
  lang,
  meta = [],
  title = '',
  imageUrl = '',
  slug = '',
}: SEOProps) => {
  const language = lang ?? 'en-us'
  const data = useStaticQuery<Queries.SEODataQuery>(
    graphql`
      query SEOData {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
        contentstackMetadataWebsite {
          default_metadata {
            page_title
            description
            image {
              description
              url
            }
          }
        }
        allContentstackTopicLocale {
          distinct(field: short_code)
        }
      }
    `,
  )

  const metaDescription =
    description ??
    data.contentstackMetadataWebsite?.default_metadata?.description
  const allSiteLocales = data.allContentstackTopicLocale.distinct
  const alternateLocales = allSiteLocales.map((siteLocale) => {
    const locale = siteLocale.toLowerCase()
    return locale === 'en-us' ? 'en' : locale
  })

  return (
    <Helmet
      htmlAttributes={{
        language,
      }}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content:
            title ??
            data.contentstackMetadataWebsite?.default_metadata?.page_title ??
            undefined,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: imageUrl,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: data.site?.siteMetadata?.author ?? undefined,
        },
        {
          name: `twitter:title`,
          content:
            title ??
            data.contentstackMetadataWebsite?.default_metadata?.page_title ??
            undefined,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          property: `twitter:image`,
          content: imageUrl,
        },
      ].concat(meta || [])}
      title={title}
    >
      <link
        href={
          lang === 'en-us'
            ? `${data.site?.siteMetadata?.siteUrl}${slug}`
            : `${data.site?.siteMetadata?.siteUrl}/${lang}${slug}`
        }
        rel="canonical"
      />
      {alternateLocales?.map((siteLocale) => {
        const href =
          siteLocale === 'en'
            ? `${data.site?.siteMetadata?.siteUrl}${slug}`
            : `${data.site?.siteMetadata?.siteUrl}/${siteLocale}${slug}`

        return (
          <link
            href={href}
            hrefLang={siteLocale}
            key={`hreflang-${siteLocale}`}
            rel="alternate"
          />
        )
      })}
    </Helmet>
  )
}

export default SEO
